@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  a {
    color: mat.m2-get-color-from-palette($primary, 400);
    text-decoration: none;

    &:hover, &:focus {
      color: mat.m2-get-color-from-palette($primary, 300);
    }
  }

  .selected {
    background-color: mat.m2-get-color-from-palette($accent) !important;

    a {
      color: mat.m2-get-color-from-palette($background, dialog) !important;
      font-weight: bold !important;
    }
  }

  .warn {
    color: mat.m2-get-color-from-palette($warn) !important;
  }

  .mat-mdc-snack-bar-container {
    @include mat.elevation(0);

    &.no-action .mat-mdc-simple-snack-bar .mdc-snackbar__label {
      text-align: center;
    }

    &.alert-message .mat-mdc-snack-bar-label .mdc-snackbar__label {
      color: mat.m2-get-color-from-palette($warn);
    }
  }
}

html {
  overflow-y: initial !important;
}

html.cdk-global-scrollblock body {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
}

main {
  margin: 10px;
}

.hide {
  display: none;
}

.clickable {
  cursor: hand;
}

.wrap {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  body {
    background-color: mat.m2-get-color-from-palette($background, dialog);
    color: mat.m2-get-color-from-palette($foreground, text);
  }

  .review-toolbar {
    background: mat.m2-get-color-from-palette($background, app-bar) !important;
  }

  .secondary-toolbar-text {
    color: mat.m2-get-color-from-palette($primary);
  }

  .countdown-timer {
    color: mat.m2-get-color-from-palette($primary);
  }

  .inspect-task {
    color: mat.m2-get-color-from-palette($accent);
  }

  .assessment-task {
    color: mat.m2-get-color-from-palette($accent);
  }

  .inspect-assessment {
    color: mat.m2-get-color-from-palette($primary);
  }

  .control-button {
    color: mat.m2-get-color-from-palette($primary);
  }

  .review-toggle-buttons {
    .mat-button-toggle {
      color: mat.m2-get-color-from-palette($primary);

      &.mat-button-toggle-checked {
        background: mat.m2-get-color-from-palette($primary);
      }

      &.mat-button-toggle-disabled {
        background: mat.m2-get-color-from-palette($background, dialog);

        &.mat-button-toggle-checked {
          background: mat.m2-get-color-from-palette($accent);
        }
      }
    }
  }
}
